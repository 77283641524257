<template>
    <div class="container container_small">
      <div class="profile" v-show="!loading">
        <div class="profile__header">
          <div class="profile__title">{{$t('profile.title')}}</div>
          <nav class="profile__nav">
            <router-link to="/" class="btn btn__secondary btn__secondary_outline-green">{{$t('menu.main')}}</router-link>
            <router-link to="/profile" class="btn btn__secondary btn__secondary_outline-green">{{$t('menu.profile')}}</router-link>
            <router-link to="/cards" class="btn btn__secondary btn__secondary_outline-green">{{$t('menu.cards')}}</router-link>
          </nav>
        </div>
        <div class="profile__info">
          <div class="profile__sidebar">
            <router-link to="/profile/personal" class="profile__sidebar-item">
              {{$t('profile.menu.personal')}}
            </router-link>
            <router-link to="/profile/contact" class="profile__sidebar-item">
              {{$t('profile.menu.contact')}}
            </router-link>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  computed: {
    ...mapGetters({
      loading: "loading",
      contact: "contact/contact"
    })
  },
  methods:{
    ...mapMutations({
      setStatusLoading: "setStatusLoading",
    }),
    ...mapActions({
      getContact:'contact/getContact'
    })
  },
  created() {
    this.setStatusLoading(true);
    this.getContact().then(()=>{
      this.setStatusLoading(false);
    });
  }
}
</script>
